import { useSnackbar } from 'notistack';
import React from 'react';
import classNames from 'classnames';
import { SnackbarContent } from '@mui/material';
import './styles/main.scss';

const InnerSnackbarUtilsConfigurator = (props) => {
    props.setUseSnackbarRef(useSnackbar());
    return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
    useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
    return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

// Helper function to create custom SnackbarContent
const createSnackbarContent = (message, variant, key) => {
    return (
        <SnackbarContent
            key={key}
            data-cy="cd-snackbarContent-snackbar"
            message={message}
            variant={variant}
            className={classNames('snackbar-content', `snackbar-content--${variant}`)}
        />
    );
};

export default { // eslint-disable-line
    success(msg, autoHideDuration = 5000) {
        this.toast(msg, 'success', autoHideDuration);
    },
    warning(msg, autoHideDuration = 5000) {
        this.toast(msg, 'warning', autoHideDuration);
    },
    info(msg, autoHideDuration = 5000) {
        this.toast(msg, 'info', autoHideDuration);
    },
    error(msg, autoHideDuration = 5000) {
        this.toast(msg, 'error', autoHideDuration);
    },
    toast(msg, variant = 'info', autoHideDuration = 5000) {
        useSnackbarRef.enqueueSnackbar(msg, {
            variant,
            autoHideDuration,
            content: (key) => createSnackbarContent(msg, variant, key) // Use custom content
        });
    }
};
